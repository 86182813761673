'use client'

import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { classNames } from '@tebuto/functions'
import LargeText from '@tebuto/typography/LargeText'
import SmallText from '@tebuto/typography/SmallText'
import { ReactNode } from 'react'

export interface PlainModalProps {
    isOpen: boolean
    title: string
    subTitle?: string
    description?: string
    verticalPadding?: boolean
    className?: string
    children: ReactNode | ReactNode[]
    closeHandler: () => void
    onModalClose?: () => void
}

export default function PlainModal({
    isOpen,
    title,
    subTitle,
    description,
    className,
    children,
    closeHandler,
    onModalClose = closeHandler,
    verticalPadding = true
}: PlainModalProps) {
    return (
        <Transition appear show={isOpen}>
            <Dialog as="div" className="relative z-50" onClose={onModalClose}>
                <TransitionChild enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </TransitionChild>
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center text-center">
                        <TransitionChild
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <DialogPanel
                                className={`${className} flex flex-col w-full h-auto md:rounded-2xl overflow-hidden bg-white text-left align-middle shadow-xl transition-all relative`}
                            >
                                <XMarkIcon className="h-6 w-6 absolute right-4 top-4 text-gray-500 hover:cursor-pointer" onClick={closeHandler} />
                                <DialogTitle className={'tracking-tight xl:text-3xl lg:text-xl text-lg px-6 md:px-10 py-4 border-b border-gray-200 bg-primary-25'}>
                                    <div>
                                        {subTitle && <SmallText className="mt-2">{subTitle}</SmallText>}
                                        <LargeText className={'text-lg font-semibold text-gray-600'}>{title}</LargeText>
                                    </div>
                                    {description && <p className="tracking-normal mt-4">{description}</p>}
                                </DialogTitle>

                                <div className={classNames('flex-grow flex flex-col overflow-hidden', verticalPadding ? 'mt-6' : '')}>{children}</div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}
